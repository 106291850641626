import React from 'react'
import moment from 'moment'
import config from '../../../constants/config'
import {getBase64, useTranslation, formatDateTime} from '../../../constants/helpers'

const Mau1 = ({id, player, season, team, sponsors, isShowBirthDay, onSuccess}) => {
  const {t} = useTranslation()
  const width = 640
  const height = width * 1.41
  const px = width / 100
  // const member_type = {
  //   football_coach: 'HUẤN LUYỆN VIÊN',
  //   football_player_type: 'VẬN ĐỘNG VIÊN',
  //   football_medical_staff: 'SĂN SÓC VIÊN',
  //   football_marketing: 'TRUYỀN THÔNG',
  //   football_coach_assistant: 'TRỢ LÝ HUẤN LUYỆN VIÊN',
  //   football_other_staff: 'THÀNH VIÊN',
  //   football_team_manager: 'TRƯỞNG ĐOÀN'
  // }
  if (!player) return null
  console.log(sponsors, 'sponsors')
  return (
    <div id={id} style={{backgroundImage: 'url(/mau1-bg.jpg)', width, height, backgroundSize: 'cover', position: 'relative'}}>
      {/* <div style={{color: '#222', textAlign: 'center', fontSize: 5 * px, position: 'absolute', top: 4 * px, left: 2 * px, right: 2 * px}}>GIẢI BÓNG ĐÁ</div> */}
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 20 * px, position: 'absolute', top: 5 * px, left: 2 * px, right: 2 * px}}>
        <span style={{color: '#222', fontWeight: '800', textAlign: 'center', fontSize: 3.8 * px, lineHeight: '124%'}}>{season?.name}</span>
      </div>
      <Image onChange={onSuccess} crossOrigin="anonymous" id={player?.id} alt={player?.name || ''} src={player?.avatar} style={{position: 'absolute', top: 29 * px, left: 31.5 * px, width: 36.5 * px, height: 36.5 * px, borderRadius: '50%', objectFit: 'cover', objectPosition: 'center', backgroundColor: '#fff'}} />
      <div style={{position: 'absolute', top: 80 * px, fontSize: 6 * px, fontWeight: '800', textAlign: 'center', width: 100 * px, color: '#FFF'}}>{player?.types?.[0]?.includes('player') ? `${t(player?.types?.[0])}` : `${t(player.positions[0])}`}</div>
      <div style={{display: 'flex', flexDirection: 'column', width: 100 * px, position: 'absolute', top: 93 * px}}>
        <span style={{color: '#fff', fontWeight: '500', textAlign: 'center', fontSize: 7 * px}}>{player?.name?.toUpperCase()}</span>
        {player.types[0] === 'football_player_type' && <span style={{color: '#fff', fontWeight: '600', textAlign: 'center', fontSize: 7 * px}}>{`${t('shirt_number')}: ${player?.shirtNumber}`}</span>}
        {isShowBirthDay && <span style={{color: '#fff', fontWeight: '500', textAlign: 'center', fontSize: 4 * px}}>{formatDateTime(player?.birthday, 'date')}</span>}
      </div>
      <div style={{position: 'absolute', top: 124 * px, left: 0, right: 0}}>
        <p style={{fontWeight: 'bold', textAlign: 'center', fontSize: 5 * px}}>{team?.name}</p>
      </div>
      {sponsors?.length > 0 && (
        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: px, position: 'absolute', top: 133 * px, height: 8 * px, left: 0, right: 0, backgroundColor: '#AB2027'}}>
          {sponsors?.map((item, idx) => (
            <Image key={idx} crossOrigin="anonymous" id={item?.id} alt={item?.name || ''} src={item?.logo} style={{width: 6 * px, height: 6 * px, borderRadius: '5%', objectFit: 'cover', objectPosition: 'center', backgroundColor: '#fff'}} />
          ))}
        </div>
      )}
    </div>
  )
}

const Image = props => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    if (data) setData(null)
    if (props?.src?.includes('ui-avatars')) {
      getBase64(config.logo).then(r => {
        setData(r)
      })
    } else {
      getBase64(props.src).then(r => {
        setData(r)
      })
    }
  }, [props.src, props.id])
  React.useEffect(() => {
    if (data) {
      props?.onChange?.()
    }
  }, [data])
  return data ? <img {...props} src={data} alt="" /> : null
}
function areEqual(p, n) {
  return JSON.stringify(p.season) === JSON.stringify(n.season) && JSON.stringify(p.player) === JSON.stringify(n.player) && JSON.stringify(p.team) === JSON.stringify(n.team) && JSON.stringify(p.sponsors) === JSON.stringify(n.sponsors)
}

export default React.memo(Mau1, areEqual)
